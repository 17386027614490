import React from "react";
import Form from "../components/common/Form";
import formFieldsConfig from "../constants/data/formFields";
import { useParams } from "react-router-dom";

export default function CreatePage({CustomForm}) {
  const {subtype,type} = useParams();
  const config = formFieldsConfig[subtype];

  return (
    <Form
      fields={config?.fields}
      type={type}
      subType={subtype} 
      action={"create"}
      title={`Create new ${config.singular} `}
    >
      {CustomForm}
    </Form>
  );
}
