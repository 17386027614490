import React from "react";

export default function Select({ label, value, name, onChange, options }) {
  const [currentValue, setCurrentValue] = React.useState(options[0]?.value);
  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e, false, name);
  };

  React.useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);
  return (
    <div>
      <div className=" flex justify-between gap-1 items-center mt-2 ">
        <select
          className="w-full border border-gray-300 rounded-md p-2"
          name={name}
          value={currentValue}
          onChange={handleInputChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
