import { Link, useNavigate, useRouteError } from "react-router-dom";
import DoubleSidebar from "../components/common/DoubleSidebar";
import sidebarMenuItems from "../constants/sidebar";
import globalConfig from "../constants/global";
import { Button } from "../components/ui/button";
import React from "react";

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  React.useEffect(()=> {
    if(error.message === "auth_required") navigate('/login');
  },[error])
  

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[320px_1fr] lg:grid-cols-[380px_1fr]">
      <div id="sidebar" className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen  flex-col">
          <div className="flex  items-center border-b px-4 min-h-16  lg:h-[60px] lg:px-6">
            <a href="/" className="flex items-center gap-2 font-semibold">
              <span className="">{globalConfig.brand}</span>
            </a>
          </div>
          <DoubleSidebar sidebarMenuItems={sidebarMenuItems} />
        </div>
      </div>
      <div id="detail" className="flex flex-col ">
        <div className="flex flex-col items-center justify-center h-screen gap-5">
          {error.message !== "auth_required" && (
            <>
              {" "}
              <h1 className="text-6xl font-semibold text-primary-500">Oops!</h1>
              <h2 className="text-2xl font-semibold text-gray-800">
                Sorry, an unexpected error has occurred.
              </h2>
              <p className="text-gray-500">
                {error.statusText || error.message}
              </p>
            </>
          )}
          {error.message === "auth_required" && <>
            <h1 className="text-2xl font-semibold text-primary-500">Auhentication required</h1>
              <h2 className="text-xl font-light  text-gray-500">
                please login
              </h2>
              <Link to="/login"><Button>Login</Button></Link>
              </>
}
        </div>
      </div>
    </div>
  );
}
