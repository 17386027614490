import React, { useState } from "react";
import { Input } from "../../ui/input";
import { Loader2 } from "lucide-react";
import { baseUrl } from "../../../api";
import RichText from "./RichText";
import MultiSelect from "./MultiSelect";
import Select from "./Select";

export default function Field({ value, hideLabel, ...props }) {
  const handleInputChange = (e) => {
    props.onChange(e, false, props.name, props.type);
  };
  const [currentValue, setCurrentValue] = useState(value);
  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  const [selectOptions, setSelectOptions] = useState([]);
  const loadOptions = async () => {
    const response = await props.options();
    setSelectOptions(response);
  }
  React.useEffect(()=> {
    if(typeof props.options === 'function') loadOptions()
      else setSelectOptions(props.options)
  },[props.options]);
  return (
    <div className="form-field flex flex-col gap-2">
      {!hideLabel &&<label className="font-bold" htmlFor={props.name}>
        {props.label} :
      </label>}
      {props.type === "image" && (
        <div className="flex justify-between gap-2 items-center">
          <Input {...props}  type="file" onChange={handleInputChange} />
          {((props.uploading && !props.isMultiple)) && (
            <span className="text-sm text-gray-500 flex items-center justify-end gap-1">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              uploading...
            </span>
          )}

          {!props.uploading && (
            <img
              className="w-64 rounded-lg border border-gray-100"
              alt=""
              src={currentValue ? `${baseUrl}/download/${currentValue}` : "https://ralfvanveen.com/wp-content/uploads/2021/06/Espace-r%C3%A9serv%C3%A9-_-Glossaire.svg"}
            />
          )}
        </div>
      ) }
      {
        (props.type === "text" || props.type === "password"  )&& (
          <Input
            {...props}
            value={currentValue}
            onChange={handleInputChange}
          />
        )
      }
      {
        props.type === "richText" && (
          <RichText
            {...props}
            value={currentValue}
            onChange={ handleInputChange}
          />
        )
      }
      {
        props.type === "multiSelect" && (
          <MultiSelect
            {...props}
            value={currentValue}
            onChange={handleInputChange}
            options={selectOptions}
          />
        )
      }
       {
        props.type === "select" && (
          <Select
            {...props}
            value={currentValue}
            onChange={handleInputChange}
            options={selectOptions}
          />
        )
      }
    </div>
  );
}
