import {
  ArrowLeft,
} from "lucide-react";

import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import { Input } from "../components/ui/input";
import {Tabs,TabsContent,} from "../components/ui/tabs";
import { Checkbox } from "../components/ui/checkbox";

export function Settings() {
  return (
    <div className=" bg-muted/40">
     
      <div className="flex min-h-screen w-3/4 mx-auto flex-col">
        <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14 mt-6">
          <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <Tabs defaultValue="all">
              <TabsContent value="all">
                <Card x-chunk="dashboard-06-chunk-0" className="p-4">
                  <CardHeader>
                    <div className="flex items-center">
                      <div className="ml-auto flex items-center gap-2">
                        <Button size="sm" className="h-8 gap-1">
                          <ArrowLeft className="h-3.5 w-3.5" />
                          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                            Return to my space
                          </span>
                        </Button>
                      </div>
                    </div>
                    <CardTitle>Settings</CardTitle>
                    <CardDescription>
                      These are your website settings, you can change them
                      anytime 👍
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
                      {/* <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">Settings</h1>
        </div> */}
                      <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
                        <nav
                          className="grid gap-4 text-sm text-muted-foreground"
                          x-chunk="dashboard-04-chunk-0"
                        >
                          <a href="#" className="font-semibold text-primary">
                            General
                          </a>
                          <a href="#">custom domain</a>
                          <a href="#">Integrations</a>
                          <a href="#">Advanced</a>
                        </nav>
                        <div className="grid gap-6">
                          <Card x-chunk="dashboard-04-chunk-1">
                            <CardHeader>
                              <CardTitle>Landing Page Name</CardTitle>
                              <CardDescription>
                                use a name that will be easy to remember and will
                                help you identify your landing page.
                              </CardDescription>
                            </CardHeader>
                            <CardContent>
                              <form>
                                <Input placeholder="landingPage Name" />
                              </form>
                            </CardContent>
                           
                          </Card>
                          <Card x-chunk="dashboard-04-chunk-2">
                            <CardHeader>
                              <CardTitle>Watermark</CardTitle>
                              <CardDescription>
                              you can remove or show the watermark on your landing page.
                              </CardDescription>
                            </CardHeader>
                            <CardContent>
                              <form className="flex flex-col gap-4">
                              
                                <div className="flex items-center space-x-2">
                                  <Checkbox id="include" defaultChecked />
                                  <label
                                    htmlFor="include"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                  >
                                    show / hide watermark
                                  </label>
                                </div>
                              </form>
                            </CardContent>
                            <CardFooter className="border-t px-6 py-4">
                              <Button>Save</Button>
                            </CardFooter>
                          </Card>
                        </div>
                      </div>
                    </main>
                  </CardContent>
                  <CardFooter></CardFooter>
                </Card>
              </TabsContent>
            </Tabs>
          </main>
        </div>
      </div>
    </div>
  );
}
