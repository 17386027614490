import React, { useReducer } from 'react';


import AppContext from './context';
import authReducer from './reducer';
import {ressource} from '../../api';

const AppState = (props) => {
  const initialState = {
    loading : false,
    submitting: false,
    currentAction: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);
  
  const search = async (query) => {
    try {
      dispatch({ type: 'set_loading', payload: true });
      const data = await ressource.search(query);
      dispatch({
        type: 'update_data',
        payload: data,

      });
      dispatch({ type: 'set_loading', payload: false });
    } catch (error) {
      dispatch({
        type: 'ressource_error',
        payload: error,
      });
      dispatch({ type: 'set_loading', payload: false });
    }
  };
  const clearError = () => {
    dispatch({ type: 'clear_error' });
  };

  const redirect = (path) => {
    dispatch({ type: 'redirect', payload: path });
    }
 

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <AppContext.Provider
      value={{
        ...state,
        search,
        clearError,
        redirect,
      }}

    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
