import { useState } from "react"
import { Button } from "../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import globalConfig from "../constants/global"
import { login } from "../api"
import { useToast } from "../components/ui/use-toast"
import { useNavigate } from "react-router-dom"

export function Login() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: ""
  });
  const {toast}= useToast();
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const {target: {name,value}} = e;
    setFormValues({...formValues, [name]: value})
  }
  const handleLogin = async() => {
    const loginResponse = await login(formValues);
    if(loginResponse.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: loginResponse.message,
      });
      return ;
    }
    localStorage.setItem("user", JSON.stringify(loginResponse.user));
    localStorage.setItem("access_token", loginResponse.tokens.access.token);
    localStorage.setItem("refresh_token", loginResponse.tokens.refresh.token);
    localStorage.setItem("user", JSON.stringify(loginResponse.user)); 
    const role = globalConfig.roles.find(role => role.name === loginResponse.user.role)
      navigate(role?.loginRedirect || '/dashboard');
  }
  return (
    <div className="flex justify-center items-center h-screen">
    <Card className="w-full max-w-sm">
      <CardHeader>
        
        <CardTitle className="text-2xl text-center">{globalConfig.brand} login</CardTitle>
        <CardDescription className="text-center">
          Enter your email below to login to your account.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div className="grid gap-2">
          <Label htmlFor="email">Email</Label>
          <Input id="email" type="email" name="email" placeholder="m@example.com" required onChange={handleInputChange} />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="password">Password</Label>
          <Input id="password" name="password" type="password" required onChange={handleInputChange} />
        </div>
      </CardContent>
      <CardFooter>
        <Button className="w-full" onClick={handleLogin}>Login</Button>
      </CardFooter>
    </Card>
    </div>
  )

}
