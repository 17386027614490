import React from 'react'
import { getThemeColor, setThemeColor } from '../api'

export default function Customize() {

  const [color, setColor] = React.useState("teal")
  const loadColor = async () => {
    const color = await getThemeColor()
    setColor(color)
    }
  React.useEffect(() => {
    loadColor()
    }, [])
    

   const colors = ["gray","red","orange","yellow","green","teal","blue","indigo","purple","pink"]
    const degrees = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900"]
    const handleChangeColor = async (selectedColor) => {
        await setThemeColor(selectedColor)
        setColor(selectedColor)

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  return (
    <div className='p-10'>
<h1 className="text-lg capitalize font-semibold md:text-2xl">Custom colors</h1>
<div className=" mt-24 shadow-lg p-6 rounded-xl bg-gray-50">
    <div className="w-full justify-center flex flex-col items-center gap-x-3">
        <div className={`w-10 h-10 rounded-full bg-${color}-600`}></div>
        <div className={`text-sm font-semibold text-${color}-900 mt-6`}>Current Color : <span className=' capitalize'> {color} </span></div>
            </div>
            <div className=''>
   <div className={`mt-2 text-sm font-semibold text-${color}-500  2xl:col-end-1 2xl:pt-3 capitalize`}>{color}</div>
   <div className="grid mt-3 grid-cols-1 sm:grid-cols-11 gap-y-3 gap-x-2 sm:mt-2 2xl:mt-0">
     {degrees.map((degree) => (
        <div className="relative flex">
         <div className="flex items-center gap-x-3 w-full sm:block sm:space-y-2">
            <div className={`h-10 w-10 rounded sm:w-full bg-${color}-${degree}`} ></div>
            <div className="px-1">
               <div className={`w-6 font-medium text-xs text-${color}-900 2xl:w-full `}>{degree}</div>
              
            </div>
         </div>
      </div>
     ))} 

   </div>
   </div>
</div>
<div className="2xl:contents pt-12">
    {colors.map((color) => (
    <div className='cursor-pointer hover:shadow-md hover:bg-gray-50 p-6 rounded-xl mt-6' onClick={()=>handleChangeColor(color)}>
   <div className={`mt-2 text-sm font-semibold text-${color}-500  2xl:col-end-1 2xl:pt-3 capitalize`}>{color}</div>
   <div className="grid mt-3 grid-cols-1 sm:grid-cols-11 gap-y-3 gap-x-2 sm:mt-2 2xl:mt-0">
     {degrees.map((degree) => (
        <div className="relative flex">
         <div className="flex items-center gap-x-3 w-full sm:block sm:space-y-2">
            <div className={`h-10 w-10 rounded sm:w-full bg-${color}-${degree}`} ></div>
            <div className="px-1">
               <div className={`w-6 font-medium text-xs text-${color}-900 2xl:w-full `}>{degree}</div>
              
            </div>
         </div>
      </div>
     ))} 

   </div>
   </div>))}
</div>

  
 

    </div>
  )
}
