import React from 'react'
import EditPage from './EditPage';
import DetailsPage from './DetailsPage';
import { useLoaderData, useParams } from 'react-router-dom';

export default function SinglePage() {
  const { data } = useLoaderData();

  const params = useParams();
  const pages = {
    edit: <EditPage data={data} {...params} />,
    view: <DetailsPage data={data} {...params}  />,
  };
  return (
    <div>
      {pages[params.action]}
    </div>
  );
}
