import InquiryProducts from "../cutomRenderer/InquiryProducts";

const singleItemConfig = {
    users : {
        title : " __name__ details",
        description : "details of  __name__ ",
        properties : [
            {
                name: "name",
                label: "Name",
                type: "text",
            },
            {
                name: "role",
                label: "Role",
                type: "text",
            },
            
          
        ],
    },
    industries : {
        title : " __name__ details",
        description : "details of the __name__ industry __description__",
        properties : [
            {
                name: "name",
                label: "Name",
                type: "text",
            },
            {
                name: "label",
                label: "Label",
                isMultiLanguage: true,
                type: "text",
            },
            {
                name: "description",
                label: "Description",
                isMultiLanguage: true,
                type: "text",
            },
          
        ],
    },
    faqs : {
        title : " __question__ details",
        description : "details of the __question__ faq",
        properties : [
            {
                name: "question",
                label: "Question",
                type: "text",
                isMultiLanguage: true,
            },
            {
                name: "answer",
                label: "Answer",
                isMultiLanguage: true,
                type: "text",
            },
           
        ],
    },
    usages : {
        title : " __name__ details",
        description : "details of the __name__ usage __description__",
        properties : [
            {
                name: "name",
                label: "Name",
                type: "text",
            },
            {
                name: "label",
                label: "Label",
                isMultiLanguage: true,
                type: "text",
            },
            {
                name: "description",
                label: "Description",
                isMultiLanguage: true,
                type: "text",
            },
          
        ],
    },
    types : {
        title : " __name__ details",
        description : "details of the __name__ type __description__",
        properties : [
            {
                name: "name",
                label: "Name",
                type: "text",
            },
            {
                name: "label",
                label: "Label",
                isMultiLanguage: true,
                type: "text",
            },
            {
                name: "description",
                label: "Description",
                isMultiLanguage: true,
                type: "text",
            },
          
        ],
    },
    'news-articles' : {
        title : " __title__ details",
        description : "details of the article:  __title__ ",
        properties : [
          
            {
                name: "title",
                label: "Title",
                isMultiLanguage: true,
                type: "text",
            },
            {
                name: "thumbnail",
                label: "Thumbnail",
                type: "image",
            },
            {
                name: "content",
                label: "Content",
                type: "richText",
                isMultiLanguage: true,
            },
          
        ],
    },
    products : {
        title : " __model__ details",
        description : "details of the __model__ product __description__",
        properties : [
            {
                name: "model",
                label: "Model",
                type: "text",
            },
            {
                name: "description",
                label: "Description",
                type: "text",
                isMultiLanguage: true,
            },
            {
                name: "image",
                label: "Image",
                type: "image",
            },
        ],
    },
    'global-translations': {
        title: '__key__ translation',
        description: 'details of the translation',
        properties: [
            {
                name: 'key',
                label: 'Key',
                type: 'text',
                
            },
            {
                name: 'values',
                label: 'Value',
                type: 'text',
                
                isMultiLanguage: true,
            },
            
        ],
    },
    inquiries : {
        title : " __email__ details",
        description : "details of ' __email__ ' inquiry ",
        properties : [
            {
                name: "email",
                label: "Email",
                type: "text",
            },
            {
                name: "phone",
                label: "Phone",
                type: "text",
            },
            {
                name: "message",
                label: "Message",
                type: "text",
            },
            {
                name: "products",
                label: "Products",
                renderer : (data) =><>
                <InquiryProducts products={data.products}/>
                </>,
            }
          
        ],
    },

};
export default singleItemConfig;