import React from "react";
import Overview from "../components/common/Overview";
import singleItemConfig from "../constants/data/single";
import { transform } from "../utils/helpers";

export default function DetailsPage({ data, subtype, type }) {
  const config = singleItemConfig[subtype];

  return (
    <Overview
      data={data}
      properties={config?.properties}
      type={type}
      subType={subtype} 
      title={transform(data, config?.title)}
      description={transform(data, config?.description)}
    />
  );
}
