import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export default function DoubleSidebar({ sidebarMenuItems }) {
  const params = useParams();
  const userRole = JSON.parse(localStorage.getItem("user") || "{}")?.role;
  const [activeSubMenuItem, setActiveSubMenuItem] = React.useState(0);
  useEffect(() => {
    const activeSubMenuItemIndex = sidebarMenuItems.findIndex(
      (item) => item.path === '/'+params.type
    );
    if(activeSubMenuItemIndex !== -1){
    setActiveSubMenuItem(activeSubMenuItemIndex);
    }
  }
  , [params.type, sidebarMenuItems,params.subtype])
  return (
    <div className="flex-1">
      <div className="flex">
        <nav className="  items-start px-2 text-sm h-screen bg-slate-100   lg:px-4">
          {sidebarMenuItems.filter(
            (item) => item?.roles?.includes(userRole)
          ).map((item, index) => (
            <Link key={"sidebar-item-"+index} to={item.path}>
              <div
                className="flex items-center justify-between px-4 py-2 text-md cursor-pointer text-muted-foreground"
                onClick={() => setActiveSubMenuItem(index)}
              >
                {item?.name}
              </div>
            </Link>
          ))}
        </nav>
        { !sidebarMenuItems[activeSubMenuItem].custom &&
        <div
          side="left"
          className="flex flex-col h-full ustify-start flex-1  px-6 "
        >
          <span className="text-lg font-semibold text-primary py-2 ">
            {sidebarMenuItems[activeSubMenuItem]?.name}
          </span>
          <hr />
          <nav className="grid gap-1 text-sm font-medium ">
            { !sidebarMenuItems[activeSubMenuItem].custom &&sidebarMenuItems[activeSubMenuItem]?.subItems
            .filter(
              (subItem) => subItem?.roles?.includes(userRole) || !subItem?.roles
            )
            .map(
              (subItem, index) => (
                <Link
                  key={"sidebar-subItem-" + index}
                  to={
                    sidebarMenuItems[activeSubMenuItem].path + "" + subItem.path
                  }
                  className="mx-[-0.65rem] flex items-center gap-2 rounded-xl px-3 py-1 text-muted-foreground hover:text-foreground"
                >
                  {subItem.name.toLowerCase() === params.subtype ?<strong className="text-indigo-700">{subItem.name}</strong>
                  :subItem.name}
                  
                </Link>
              )
            )}
          </nav>

        </div>
}
      </div>
    </div>
  );
}
