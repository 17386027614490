import { ressource } from "../../api";
import Product from "../customForms/Product";
import globalConfig from "../global";

const formFieldsConfig = {
    users: {
        singular: 'user',
        title: 'Editing user: __name__',
        fields: [
            {
                name: "name",
                label: "Name",
                type: "text",
            },
            {
                name: "email",
                label: "Email",
                type: "text",
            },
            {
                name: "password",
                label: "Password",
                type: "password",
            },
            {
                name: "role",
                label: "Role",
                type: "select",
                options: globalConfig.roles.filter(role => !role.notListed).map(({name, label}) => ({ value: name, label: label })),
            },
        ],
    },
    industries: {
        singular: 'industry',
        title: 'Editing industry: __name__',
        fields: [
            {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
            },
            {
                name: 'label',
                label: 'Label',
                type: 'text',
                isMultiLanguage: true,
                required: true,
            },
            {
                name: 'content',
                label: 'Content',
                type: 'richText',
                isMultiLanguage: true,
                required: true,
            },
        ],
    },
    usages: {
        singular: 'usage',
        title: 'Editing usage: __name__',
        fields: [
            {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
            },
            {
                name: 'label',
                label: 'Label',
                type: 'text',
                isMultiLanguage: true,
                required: true,
            },
            {
                name: 'content',
                label: 'Content',
                type: 'richText',
                isMultiLanguage: true,
                required: true,
            },
        ],
    },
    types: {
        singular: 'type',
        title: 'Editing type: __name__',
        fields: [
            {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
            },
            {
                name: 'label',
                label: 'Label',
                type: 'text',
                isMultiLanguage: true,
                required: true,
            },
            {
                name: 'content',
                label: 'Content',
                type: 'richText',
                isMultiLanguage: true,
                required: true,
            },
        ],
    },
    'news-articles': {
        singular: 'article',
        title: 'Editing Article: __title__',
        fields: [
            
            {
                name: 'title',
                label: 'Title',
                type: 'text',
                isMultiLanguage: true,
                required: true,
            },
            {
                name: 'thumbnail',
                label: 'Thumbnail',
                type: 'image',
                required: true,
            },
            {
                name: 'content',
                label: 'Content',
                type: 'richText',
                isMultiLanguage: true,
                required: true,
            },
        ],
    },
    faqs: {
        singular: 'faq',
        title: 'Editing faq: __question__',
        fields: [
            {
                name: 'question',
                label: 'Question',
                type: 'text',
                required: true,
                isMultiLanguage: true,
            },
            {
                name: 'answer',
                label: 'Answer',
                type: 'text',
                required: true,
                isMultiLanguage: true,
            },
            
        ],
    },
  
    products: {
        singular: 'product',
        title: 'Edit __model__ product',
        fields: [
            {
                name: 'model',
                label: 'Model',
                type: 'text',
                required: true,
            },
            {
                name : 'label',
                label: 'Label',
                type: 'text',
                required: true,
                isMultiLanguage: true,

            },
            {
                name: 'description',
                label: 'Description',
                type: 'text',
                isMultiLanguage: true,
                required: true,
            },
          
            {
                name: 'thumbnail',
                label: 'Thumbnail',
                type: 'image',
                required: true,
            },
            
            {
                name: 'images',
                label: 'Images',
                type: 'image',
                required: true,
                isMultiple: true,
            },
            
            {
                name : 'industries',
                label: 'Industries',
                type: 'multiSelect',
                required: true,
                options: async () =>  await ressource('industries').getRefs(),
            
            },  {
                name : 'usages',
                label: 'usages',
                type: 'multiSelect',
                required: true,
                options: async () =>  await ressource('usages').getRefs(),
            
            },
            {
                name : 'types',
                label: 'types',
                type: 'multiSelect',
                required: true,
                options: async () =>  await ressource('types').getRefs(),
            
            },
            {
                name: 'attributes',
                label: 'Attributes',
                type: 'customForm',
                component : (props) =><Product {...props}/>,
            }
        ],
    },
    'global-translations': {
        singular: 'translation',
        title: 'Editing translation: __key__',
        fields: [
            {
                name: 'key',
                label: 'Key',
                type: 'text',
                required: true,
            },
            {
                name: 'values',
                label: 'Value',
                type: 'text',
                required: true,
                isMultiLanguage: true,
            },
            
        ],
    },
  
};
export default formFieldsConfig;