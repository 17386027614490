import { Button } from "../../ui/button";
import React from "react";
import { Trash } from "lucide-react";

export default function MultiSelect({ label, value, name, onChange, options }) {
  const [currentValue, setCurrentValue] = React.useState(options[0]?.value);
  const [selected, setSelected] = React.useState([]);
  const [initialOptions, setInitialOptions] = React.useState(options);
  const handleInputChange = (e) => {
    setCurrentValue(e.target.value);
  };
  
  React.useEffect(() => {
    console.log(value)
    if (value) {
        setSelected(value);
        setInitialOptions(options.filter(option => !value.includes(option.value)))
        setCurrentValue(options.filter(option => !value.includes(option.value))[0]?.value);
    }
    else {
        setInitialOptions(options);
        setCurrentValue(options[0]?.value);
    }
  }, [options]);
  return (
    <div>
      <div className=" flex justify-between gap-1 items-center mt-2 ">
        <select
          className="w-full border border-gray-300 rounded-md p-2"
          name={name}
          value={currentValue}
          onChange={handleInputChange}
        >
          {initialOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Button
          disabled={initialOptions.length === 0}
          onClick={() => {
            setSelected((prev) => {
              onChange(
                { target: { value: [...prev, currentValue], name } },
                false,
                label,
                "multiSelect"
              );
              return [...prev, currentValue];
            });
            setInitialOptions((options) => {
              let newOptions = options.filter(
                (option) => option.value !== currentValue
              );
              if (newOptions.length > 0) setCurrentValue(newOptions[0].value);

              return newOptions;
            });
          }}
        >
          add
        </Button>
      </div>
      <div className="flex  gap-1 mt-2">
        {selected.map((item) => (
          <div
            key={item}
            className="bg-gray-200 text-gray-500 font-bold p-2 rounded-md relative px-5"
          >
            <span>
              {options?.find((option) => option?.value === item)?.label}
            </span>
            <span
              className="absolute top-1 right-1 cursor-pointer"
              onClick={() => {
                setSelected((prev) => {
                  const newSelected = prev.filter((option) => option !== item);
                  onChange(
                    { target: { value: newSelected, name } },
                    false,
                    label,
                    "multiSelect"
                  );
                  return newSelected;
                });

                setInitialOptions([
                  ...initialOptions,
                  options?.find((option) => option?.value === item),
                ]);
                onChange(
                  { target: { value: selected, name } },
                  false,
                  label,
                  "multiSelect"
                );
              }}
            >
              <Trash color="rgb(200,10,9,9)" size={15} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
