import { defaultLanguage } from "../../../constants/languages";
import React from "react";

const  Text = ({ label, value, isMultiLanguage }) =>{
  return (
    <>
      <h2 className="text-lg font-semibold">{label}</h2>
      <h5 className="text-md font-medium text-gray-800">
        {isMultiLanguage ? value[defaultLanguage] : value}
      </h5>
    </>
  );
}

export default Text;