import { useEffect, useState } from "react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
  } from "../../components/ui/alert-dialog"
  
  
  export function ConfirmDialog({ open, confirmMessage,action, onConfirm, onCancel}) {
    const [isOpen, setIsOpen] = useState(open);
    useEffect(() => {
        setIsOpen(open);
        }
        , [open])
    return (
      <AlertDialog open={isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              {confirmMessage || "This action cannot be undone. This will permanently delete the item."}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={onCancel}  >Cancel</AlertDialogCancel>
            <AlertDialogAction  onClick={()=> onConfirm(action)} >Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  