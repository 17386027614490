import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

import Text from "./preview/Text";
import Image from "./preview/Image";

export default function Overview({
  data,
  properties,
  title,
  description,
  type,
  subType,
}) {
  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <div className="flex flex-col w-full sm:gap-4 sm:py-4 sm:pl-14">
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <div className="mx-auto w-3/4 flex-1 auto-rows-max gap-4">
            <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
              <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                <Card x-chunk="dashboard-07-chunk-0">
                  <CardHeader>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription>{description}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-6">
                      {/* should be base in property type */}
                      {properties.map(
                        ({ label, name, type, isMultiLanguage, renderer }) => (
                          <>{renderer ? 
                          <>{renderer(data)}</>
                          : <div className="grid gap-3 grid-cols-2">
                            {type === 'text' && <Text
                              key={name}
                              label={label}
                              value={data[name]}
                              isMultiLanguage={isMultiLanguage}
                            />}
                            {type === 'image' && (
                            <Image label={label} value={data[name]} />
                            )}
                          </div>
                        }
                        </>
                         
                        )
                      )}
                    </div>
                  </CardContent>
                  <CardFooter>
                    {/* back to items */}
                    <Link to={`/${type}/${subType}`}>
                      <Button>Back </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
