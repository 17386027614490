import React from 'react'
import RichTextEditor from 'react-rte';
function decodeHTMLEntities(text) {
  if( typeof text !== 'string') return text;
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

export default function RichText({label, value,name, onChange}) {

  const [currentValue, setCurrentValue] = React.useState(RichTextEditor.createEmptyValue());
  React.useEffect(() => {
    if(typeof value === "string")
        setCurrentValue(RichTextEditor.createValueFromString(decodeHTMLEntities(value), 'html'))
    else setCurrentValue(value)
  }
  ,[value])
  return (
    < div className='w-full'>
        <RichTextEditor
        value={currentValue }
        onChange={(content) => {
          onChange({target: {value: content, name}}, false, label, "text")
          setCurrentValue(content)
        }}
      />              
    </div>
  )
}
