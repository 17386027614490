import React from "react";

import { transform } from "../utils/helpers";
import Form from "../components/common/Form";
import formFieldsConfig from "../constants/data/formFields";

export default function EditPage({ data, subtype, type }) {
  const config = formFieldsConfig[subtype];

  return (
    <Form
      data={data}
      fields={config?.fields}
      type={type}
      subType={subtype} 
      action={"edit"}
      title={transform(data, config?.title)}
      />
  );
}
