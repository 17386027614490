import React from 'react'
import sidebarMenuItems from '../constants/sidebar'
import { Link, useParams } from 'react-router-dom';

export default function TypeOverview() {
  const { type } = useParams();
  
  const userRole = JSON.parse(localStorage.getItem("user") || "{}")?.role;
  const subTypes = sidebarMenuItems.find(item => item.ressource === type)?.subItems.filter(
    (subItem) => subItem?.roles?.includes(userRole) 
  );
  const typeName = sidebarMenuItems.find(item => item.ressource === type)?.name;
  return (
    <> 
    <h1 className='text-2xl font-bold text-center mt-4 text-gray-700' >{typeName}</h1>
    <div className='flex gap-2 flex-wrap' >
      {subTypes?.map((subType, index) => (
        <Link key={index} to={`/${type}${subType.path}`}>
          <div className=' border-2 border-black p-4 m-4 rounded-lg cursor-pointer font-bold min-w-48 text-center' >
            <h3>{subType.name}</h3>
          </div>
        </Link>  
      ))}
    </div>
    </>
     
  )
}
