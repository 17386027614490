import { Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import FormField from "./FormField";
import { useEffect, useState } from "react";
import { ressource, uploadFile } from "../../api";
import { useToast } from "../ui/use-toast";

export default function Form({
  data = {},
  fields,
  title,
  type,
  subType,
  action,
}) {
  const [formValues, setFormValues] = useState(data);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleChange = async (
    e,
    isMultiLanguage,
    name,
    type,
    isMultiple = false,
    index=0
  ) => {
    if (isMultiLanguage) {
      const lang = e.target.name.split("_")[0];
      const value = e.target.value;
      if (!formValues[name]) {
        setFormValues({ ...formValues, [name]: { [lang]: value } });
        return;
      } else {
        setFormValues({
          ...formValues,
          [name]: { ...formValues[name], [lang]: value },
        });
        return;
      }
    }
    if (type === "image") {
      try {
        setUploading(true);
        const uploadedUrl = await uploadFile(e.target.files[0], name);
        setUploading(false);
        if (uploadedUrl.data.key !== "") {
          if (isMultiple) {
            if(!formValues[name]){
              setFormValues({ ...formValues, [name]: [uploadedUrl.data.key] });
            }
            else{
              const newValue = [...formValues[name]];
              newValue[index] = uploadedUrl.data.key;
              setFormValues({ ...formValues, [name]: newValue });
            }
          } else {
            setFormValues({ ...formValues, [name]: uploadedUrl.data.key });
          }
          return;
        }
      } catch (error) {
        setUploading(false);
        setError(error);
        return;
      }
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

    console.log(formValues);
  };

  useEffect(() => {
    if (error)
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: error?.message,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleRichTextFields = (formValues) => {
    const newFormValues = { ...formValues };
    fields.forEach((field) => {
      if (field.type === "richText") {
        if (field.isMultiLanguage) {
          console.log("is  multi language");
          Object.keys(formValues[field.name]).forEach((lang) => {
            console.log(formValues[field.name][lang]);
            newFormValues[field.name][lang] =
              formValues[field.name][lang].toString("html");
          });
        } else {
          console.log("is not multi language");
          newFormValues[field.name] = formValues[field.name].toString("html");
        }
      }
    });
    return newFormValues;
  };
  const handleSubmit = async () => {
    const api = ressource(subType);

    try {
      console.log(formValues);
      console.log(handleRichTextFields(formValues));
      setSubmitting(true);
      if (action === "edit") {
        await api.edit(handleRichTextFields(formValues), formValues._id || formValues.id);
        navigate(`/${type}/${subType}/view/${formValues._id || formValues.id}`);
      } else {
        const response = await api.create(handleRichTextFields(formValues));
        navigate(`/${type}/${subType}/view/${response._id || response.id}`);
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      setError(error);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <div className="flex flex-col w-full sm:gap-4 sm:py-4 sm:pl-14">
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <div className="mx-auto w-3/4 flex-1 auto-rows-max gap-4">
            <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
              <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
                <Card x-chunk="dashboard-07-chunk-0">
                  <CardHeader>
                    <CardTitle>{title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-6">
                      {/* should be base in property type */}
                      {fields?.map(
                        ({ label, name, type, isMultiLanguage, ...rest }) => {
                          return type !== "customForm" ? (
                            <FormField
                              key={name}
                              label={label}
                              name={name}
                              value={formValues[name]}
                              uploading={uploading}
                              type={type}
                              isMultiLanguage={isMultiLanguage}
                              onChange={handleChange}
                              {...rest}
                            />
                          ) : (
                            <div key={name}>
                              <label className="font-bold">{label}</label>
                              {rest.component({
                                label,
                                name,
                                type,
                                isMultiLanguage,
                                value: formValues[name],
                                onChange: handleChange,
                              })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </CardContent>
                  <CardFooter>
                    {/* back to items */}
                    <div className="flex justify-between w-full">
                      <Link to={`/${type}/${subType}`}>
                        <Button>Back</Button>
                      </Link>
                      <Button
                        variant="secondary"
                        className="capitalize"
                        onClick={handleSubmit}
                      >
                        {submitting ? "submitting ..." : action}{" "}
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
