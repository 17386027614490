import { ressource } from "../api";
import sidebarMenuItems from "../constants/sidebar";

export async function loader({ params }) {
  const { type,subtype, action, id } = params;
  const routeConfig = sidebarMenuItems.find((item) => item.ressource === type).subItems.find((item) => item.ressource === subtype);
  if(!action) {
    const data = await ressource(subtype).search({ customEndpoint: routeConfig?.customEndpoints?.search });
  return {data} ;
  }
  else {
    console.log(sidebarMenuItems, type,subtype);
    const data = await ressource(subtype).getById(id,routeConfig?.populate || '')
    return {data} ;
  }
}
