import React from "react";
import Field from "./form/Field";
import MultiLanguageField from "./form/MultiLanguageField";
import languages from "../../constants/languages";
import { Button } from "../ui/button";

export default function FormField(props) {
  const [value, setValue] = React.useState(props.value || [""]);
  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  const handleMultiple = (index) => (e) => {
    console.log(e);
    const newValue = [...value];
    newValue[index] = e.target.value;
    setValue(newValue);
    props.onChange(e , false, props.name, props.type, true, index);
  };
  if (props.isMultiLanguage)
    return <MultiLanguageField languages={languages} {...props} />;
  if (props.isMultiple)
    return (
      <>
        {" "}
        <Button onClick={() => setValue([...value, ""])}>Add</Button>
        {value.map((item, index) => (
          <Field key={index} {...props} value={item} onChange={handleMultiple(index)} />
        ))}
      </>
    );

  switch (props.type) {
    case "text":
      return <Field {...props} />;
    default:
      return <Field {...props} />;
  }
}
