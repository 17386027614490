import Image from '../../components/common/preview/Image'
import React from 'react'

export default function InquiryProducts({products}) {
  return (
    <div>
      {products.map(({product,additionalInformations}, index) => {
        return (
            <div className='flex w-full gap-4 bg-gray-50 mb-2'>
            <Image value={product.thumbnail} />
            <div key={index} className="flex w-full gap-3 flex-col justify-start  p-4  items-start">
            <div className='font-bold'>{product.model}</div>
            
            <p className='font-normal'> additional informations: {additionalInformations}</p>
          </div>
          </ div>
          
        )
      })}
    </div>
  )
}
