import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./style.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import ErrorPage from "./routes/error-page";
import Ressource from "./components/common/Ressource";
import TypeOverview from "./pages/TypeOverview";
import { loader as rootLoader } from "./routes/data";
import SinglePage from "./pages/SinglePage";
import CreatePage from "./pages/CreatePage";
import AppState from "./contexts/app/state";
import { Toaster } from "./components/ui/toaster";
import { Settings } from "./pages/Settings";
import { Login } from "./pages/Login";
// import CustomColors from "./pages/CustomColors";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {path:"/settings",element:<Settings/>},
      { path: "/:type", element: <TypeOverview /> },
      { path: "/:type/:subtype", element: <Ressource />, loader: rootLoader },
      { path: "/:type/:subtype/new", element: <CreatePage /> },
      {
        path: "/:type/:subtype/:action/:id",
        loader: rootLoader,
        element: <SinglePage />,
      },
     
    ],
  },
  {
    path: "/login",
    element: <Login/>,

  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppState>
    <Toaster />
    <RouterProvider router={router} />
    <TooltipProvider>
      {/* <Dashboard /> */}
      {/* <App /> */}
      {/* <Settings /> */}
      {/* <Profile/> */}
    </TooltipProvider>
    </AppState>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
