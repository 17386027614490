import { defaultLanguage } from "../constants/languages";

const transform = (data,from, betweenChar ='__') => {
    // match all the properties in the string that are between the betweenChar
    const properties = from?.match(new RegExp(`${betweenChar}(.*?)${betweenChar}`, 'g'));
    properties?.forEach((property) => {
      
      // remove the betweenChar from the property
      const value = data[property.replace(new RegExp(betweenChar, 'g'), '')];
      from = from.replace(property, typeof value === "object" ? value[defaultLanguage]: value);
    }
    );
    return from;
  }

    export { transform }