import { baseUrl } from "../../..//api";
import React from "react";

export default function Image({ label, value, className }) {
  return (
    <>
    
      {label && <h2 className="text-lg font-semibold flex-1">{label}</h2>
      }<img
        src={`${baseUrl}/download/${value}`}
        alt={label}
        className={"w-36 rounded-lg border border-gray-400 " + className} 
      />
    </>
  );
}
