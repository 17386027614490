import React from 'react'
import globalConfig from '../global';
import MultiLanguageField from '../../components/common/form/MultiLanguageField';
import languages from '../languages';
export default function Product({ value,label, name,  onChange}) {
    const [attributes, setAttributes] = React.useState(globalConfig.productAttributes);
    const [formValues, setFormValues] = React.useState({});
    const [labelFormValues, setLabelFormValues] = React.useState({});
    
    React.useEffect(() => {
        if(value){
            const formValues = value.reduce((acc, current) => {
                acc[current.name] = current.variants;
                return acc;
            }, {});
            setFormValues(formValues);
        }
        if(value){
          const labelFormValues = value.reduce((acc, current) => {
              acc[current.name] = current.labels;
              return acc;
          }, {});
          setLabelFormValues(labelFormValues);
      }
    }
    ,[])
    
    const handleChange = (e,attribute) => {
        const lang = e.target.name.split("_")[0];
        const value = e.target.value;
        console.log(lang, value);
        if (!formValues[attribute]) {
          
          setFormValues({ ...formValues, [attribute]: { [lang]: value } });
          
        } else {
          setFormValues({
            ...formValues,
            [attribute]: { ...formValues[attribute], [lang]: value },
          });
          
        };

      const changedValue = Object.entries(formValues).map(([key, value]) => {return { name: key, variants : value,
        labels : labelFormValues[key] } });
        onChange(
            { target: { value: changedValue, name } },
            false,
            label,
            "customForm"
          );
       
        
    }
    const handleLabelChange = (e,attribute) => {
      const lang = e.target.name.split("_")[0];
      const value = e.target.value;
      console.log(lang, value);
      if (!labelFormValues[attribute]) {
        
        setLabelFormValues({ ...labelFormValues, [attribute]: { [lang]: value } });
        
      } else {
        setLabelFormValues({
          ...labelFormValues,
          [attribute]: { ...labelFormValues[attribute], [lang]: value },
        });
        
      };
      const changedValue = Object.entries(formValues).map(([key, value]) => {return { name: key, variants : value,
        labels : labelFormValues[key] } });
      onChange(
          { target: { value: changedValue, name } },
          false,
          label,
          "customForm"
        );
     
      
  }
  return (
    <div>
      <div className="grid grid-cols-1 gap-4">
        {attributes.map((attribute, index) => (
          <div key={index} className="flex justify-between gap-2 items-center mt-2">
            <label className="text-gray-600">{attribute}</label>
           
            <MultiLanguageField 
                value={labelFormValues[attribute]} 
                onChange={(e)=>handleLabelChange(e,attribute)} 
                name={attribute} 
                label={attribute+" label"} 
                languages={languages} 
                type="text"
            />
             <MultiLanguageField 
                value={formValues[attribute]} 
                onChange={(e)=>handleChange(e,attribute)} 
                name={attribute} 
                label={attribute+" value"} 
                languages={languages} 
                type="text"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
