import CustomColors from "../pages/CustomColors";

const sidebarMenuItems = [
    {
      name: "Dashboard",
      path: "/dasboard",
      ressource: "dasboard",
      roles : ['admin', 'super-admin'],
      subItems: [
        {
          name: "Dashboard",
          path: "",
          ressource: "dashboard",
        },

  
      ],
    },
      {
        name: 'Content Manager',   
        path: '/content-manager', 
        ressource: 'content-manager', 
        roles: ['writer', 'admin', 'super-admin', 'manager','content-manager'],
        subItems: [
          {
            name: 'News articles',
            path: '/news-articles',
            ressource: 'news-articles',
            roles: ['content-manager', 'admin', 'super-admin']
          },
          {
            name: 'Faqs',
            path: '/faqs',
            ressource: 'faqs',
            roles: ['content-manager', 'admin', 'super-admin']
          },
          {
            name: 'Industries',
            path: '/industries',
            ressource: 'industries',
            roles: [ 'admin', 'super-admin']
          },
          {
            name: 'Usages',
            path: '/usages', 
            ressource: 'usages',
            roles: [ 'admin', 'super-admin']
          },
          {
            name: 'Types',
            path: '/types',
            ressource: 'types',
            roles: [ 'admin', 'super-admin']
          },
          {
            name: 'Products',
            path: '/products',
            ressource: 'products',
            roles: ['manager', 'admin', 'super-admin']
          },
          {
            name: 'global translations',
            path: '/global-translations',
            roles : ['content-manager','admin', 'super-admin'],
            ressource: 'global-translations',
          }
        ],
      },
      {
        name : 'Users',
        path: '/users',
        ressource: 'users',
        roles: ['admin', 'super-admin'],
        subItems: [
          {
            name: 'Users',
            path: '/users',
            ressource: 'users',
            roles: ['admin', 'super-admin'],
            customEndpoints : {
              search : '',
              
            }
          },
          // {
          //   name: 'Roles',
          //   path: '/roles',
          //   ressource: 'roles',
          // },
        ],
      },
      {
        name : 'Inquiries',
        path: '/inquiries',
        ressource: 'inquiries',
        roles: ['admin', 'super-admin', 'manager'],
        subItems: [
          {
            name: 'Inquiries',
            path: '/inquiries',
            ressource: 'inquiries',
            roles: ['admin', 'super-admin', 'manager'],
            populate : 'products.product'
          },
        ],
      },
      // {
      //   name: 'Settings',
      //   path: '/settings',
      //   ressource: 'settings',
      //   roles: ['admin', 'super-admin'],
      //   subItems: [
      //     {
      //       name: 'Settings',
      //       path: '/settings',
      //       ressource: 'settings',
      //       roles: ['admin', 'super-admin'],
      //     },
      //     {
      //       name: 'Languages',
      //       path: '/languages',
      //       ressource: 'languages',
      //       roles: ['admin', 'super-admin'],
      //     },
      //     {
      //       name: 'theme',
      //       path: '/theme',
      //       ressource: 'theme',
      //       roles: ['admin', 'super-admin'],
      //     }
      //   ],
      // },
      {
        name: 'Custom colors',
        custom : true,
        path:'/custom-colors',
        page : <CustomColors/>,
        
        roles: ['admin', 'super-admin'],
      },
  
  
    
  ];

export default sidebarMenuItems;