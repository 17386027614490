
import DoubleSidebar from "../components/common/DoubleSidebar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import sidebarMenuItems from "../constants/sidebar";
import globalConfig from "../constants/global";
import React, { useEffect } from "react";
import { CircleUser } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";


export default function Root() {
  const navigate = useNavigate();
  
  const params = useParams();

  const [activeSubMenuItem, setActiveSubMenuItem] = React.useState(0);
  useEffect(() => {
    const activeSubMenuItemIndex = sidebarMenuItems.findIndex(
      (item) => item.path === '/'+params.type
    );
    if(activeSubMenuItemIndex !== -1){
    setActiveSubMenuItem(activeSubMenuItemIndex);
    }
  }
  , [params.type, sidebarMenuItems,params.subtype])
  const handleLogout = () => {
    
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user"); 
      navigate('/login');
  }
  return (
    
    <div className="grid min-h-screen w-full md:grid-cols-[320px_1fr] lg:grid-cols-[380px_1fr]">
      <div id="sidebar" className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen  flex-col">
          <div className="flex  items-center border-b px-4 min-h-16  lg:h-[60px] lg:px-6">
            <a href="/" className="flex items-center gap-2 font-semibold">
              <span className="">{globalConfig.brand}</span>
            </a>
          </div>
          <DoubleSidebar sidebarMenuItems={sidebarMenuItems} />
        </div>
      </div>
      <div id="detail" className="flex flex-col">
      <header className="flex min-h-16 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
        <p className="flex items-center gap-2 text-lg font-semibold">
          
        </p>
        <div className="w-full flex-1"></div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="secondary" size="icon" className="rounded-full">
              <CircleUser className="h-5 w-5" />
              <span className="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Support</DropdownMenuItem>
            <DropdownMenuSeparator /> */}
            <DropdownMenuItem><span className=" cursor-pointer" onClick={handleLogout}>Logout</span></DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header>
        {(sidebarMenuItems[activeSubMenuItem]).custom ?
        (sidebarMenuItems[activeSubMenuItem]).page
            :<Outlet/>
            }
      </div>
    </div>
  );
}
