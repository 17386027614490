import { baseUrl, ressource } from "../../api/"

const tablesConfig = {
  users : {
    singular: 'user',
    name: 'users',
    idKey: '_id',
    columns: [
        
        {
            name: "_id",
            label: "ID",
          },
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'role',
            label: 'Role',
        },
    ],
    actions : [
        {
            label: "Add",
            name: "new",
            redirect: true,
            isGlobal: true
          },
        {
            label: "Edit",
            name: "edit",
            redirect: true,
          },
          {
            label: "Details",
            name: "view",
            redirect: true,
          },
          {
            label: "Remove",
            name: "delete",
            confirm: true,
            confirmMessage: "are you sure you want to delete this item (user)?",
            handler: async(id) => await ressource('industries').remove(id),
            updateData : (before, after) => before.filter((item) => item._id !== after._id)
        },
    ]
},
    industries : {
        singular: 'industry',
        name: 'industries',
        idKey: '_id',
        columns: [
            
            {
                name: "_id",
                label: "ID",
              },
            {
                name: 'name',
                label: 'Name',
            },
            {
                name: 'label',
                isMultiLanguage: true,
                label: 'Label',
            },
        ],
        actions : [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (industry)?",
                handler: async(id) => await ressource('industries').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    faqs : {
        singular: 'faq',
        name: 'faq',
        idKey: '_id',
        columns: [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                name: "_id",
                label: "ID",
              },
            {
                name: 'question',
                label: 'Question',
            isMultiLanguage: true,
            },
            {
                name: 'answer',
                label: 'Answer',
                isMultiLanguage: true,
            },
        ],
        actions : [
          {
            label: "Add",
            name: "new",
            redirect: true,
            isGlobal: true
          },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (faq)?",
                handler: async(id) => await ressource('faqs').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    products : {
        singular: 'product',
        name: 'products',
        idKey: '_id',
        columns: [
            {
                name: "_id",
                label: "ID",
              },
              {
                name: 'thumbnail',
                label: 'Thumbnail',
                renderer: (value) => {if(value) return( <img src={`${baseUrl}/download/${value}`}
                className="w-12 h-12 rounded-full border-2 border-gray-300"
                alt={value+' product image'} />)
                else return <img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIALcAwwMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABAUBAgMGB//EAC4QAQACAQIEBAQGAwAAAAAAAAABAgMEEQUhMVESEyJBUmFxkSNCgaHB0TIzkv/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/APoIDo5gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM+G3wz9gYAAAAAAAAAAAAAAAAAAAAB202nvqL+GnKI627A548d8t4pjrNrT7QstPwusbTntvPw16fdM0+DHp6eHHH1n3l1ZtakaY8OPF/rx1r9IdN2BFa3pTJG16Vt9Y3Rc3DcF+dN8c/LnH2TAFFqNFmwbzMeKnxVR3pULV8Ppl3ti2pft7S1KzYpx0vgy0tNbY7bx8mFRoAAAAREzMREbzPtAt+F6etMUZpje9uk9oKRAjRamY38mdvnMOF6Wpaa3rNZj2mHpEfW6euowzy9dY3rP8M61iiAaZAAAZpW17xSkb2mdogHTTYL6jLFKfrPaF7hxUw44pSNoj92mk09dNiisc7TztPeXZm1qQARQAAAAAAAHmgG2AABe8PvF9Jj2/LHhn9FE7abU5NNfenOJ61npKVYv2uW8Y8dr26VjdBjiuPbnivE9o2Q9XrL6n07eGkflj+UxdRgGmQABbcM0vl0868eu0emO0InDtN5+XxWj8OnX5z2XSWtSADKgAAAAAAAAAKu3Cr/lzVn612Rs2iz4udqbx3rzXoupjzQvdRo8OfeZr4b/FVVanSZdPO9o8VPa0LqYjgKgAAAA2x0tlyVpSN7WnaGq34ZpvLx+bePXaOXyhKsSsGKuDFXHXpHWe8ugMtAAAAAAAAAAAAAABMRMbTG8ACs1nDtt76ePrT+la9Kh63Q1z73x7VyftZZUsUwzatqWmtomLR1iWGmQEjR6W2pv2pH+VgdOHaXzr+ZePw6z/ANSuWKUrjpFKRtWOkMsWtyAAAAAAAAAAAAAAAAAAAAI+r0mPUxz9N46WhXW4bqInaPDaO8SuRdTFZg4XO++e0bfDX+1lSlcdYrSsVrHSIZE1cAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf/9k="
                className="w-12 h-12 rounded-full border-2 border-gray-200"
                alt="default product image" />
              },
            },
            {
                name: 'model',
                label: 'Model',
            },
           
            {
                name: 'label',
                label: 'Label',
                isMultiLanguage: true,
            },
            {
                name: 'description',
                label: 'Description',
                isMultiLanguage: true,
            },
        ],
        actions : [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (product)?",
                handler: async(id) => await ressource('products').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    usages : {
        singular: 'usage',
        name: 'usages',
        idKey: '_id',
        columns: [
            {
                name: "_id",
                label: "ID",
              },
            {
                name: 'name',
                label: 'Name',
            },
            {
                name: 'label',
                label: 'Label',
                isMultiLanguage: true,
            },
        ],
        actions : [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (usage)?",
                handler: async(id) => await ressource('usages').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    types : {
        singular: 'type',
        name: 'types',
        idKey: '_id',
        columns: [
            {
                name: "_id",
                label: "ID",
              },
            {
                name: 'name',
                label: 'Name',
            },
            {
                name: 'label',
                label: 'Label',
                isMultiLanguage: true,
            },
        ],
        actions : [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (type)?",
                handler: async(id) => await ressource('types').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    'news-articles' : {
        singular: 'article',
        name: 'news-articles',
        idKey: '_id',
        columns: [
            {
                name: "_id",
                label: "ID",
              },
            {
                name: 'title',
                label: 'title',
                isMultiLanguage: true,
            },
        ],
        actions : [
            {
                label: "Add",
                name: "new",
                redirect: true,
                isGlobal: true
              },
            {
                label: "Edit",
                name: "edit",
                redirect: true,
              },
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (type)?",
                handler: async(id) => await ressource('types').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    inquiries : {
        singular: 'inquiry',
        name: 'inquiries',
        idKey: '_id',
        
        columns: [
         
            {
                name: 'email',
                label: 'Email',
            },
            {
                name: 'phone',
                label: 'Phone',
            },
            {
              name: 'status',
              label: 'Status',
          },
          {
            name:"message",
            label: "additional informations"
          }
            
        ],
        actions : [
           
            {
                label: "Affect to manager",
                name: "affect-to-manager",
                redirect: true,
              },
              
              {
                label: "Details",
                name: "view",
                redirect: true,
              },
              {
                label: "Remove",
                name: "delete",
                confirm: true,
                confirmMessage: "are you sure you want to delete this item (inquiry)?",
                handler: async(id) => await ressource('inquiries').remove(id),
                updateData : (before, after) => before.filter((item) => item._id !== after._id)
            },
        ]
    },
    'global-translations' : {
      singular: 'global translation',
      name: 'global-translations',
      title : 'list of global translations',
      idKey: '_id',
      columns: [
          {
              name: "_id",
              label: "ID",
            },
          {
              name: 'key',
              label: 'Key',
          },
          {
              name: 'values',
              label: 'value',
              isMultiLanguage: true,
          },
      ],
      actions : [
          {
              label: "Add",
              name: "new",
              redirect: true,
              isGlobal: true
            },
          {
              label: "Edit",
              name: "edit",
              redirect: true,
            },
            {
              label: "Details",
              name: "view",
              redirect: true,
            },
            {
              label: "Remove",
              name: "delete",
              confirm: true,
              confirmMessage: "are you sure you want to delete this item (translation)?",
              handler: async(id) => await ressource('types').remove(id),
              updateData : (before, after) => before.filter((item) => item._id !== after._id)
          },
      ]
  },
}
export default tablesConfig;