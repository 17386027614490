import { CircleUser } from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { DataTable } from "./DataTable";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import tablesConfig from "../../constants/data/table";
import { ConfirmDialog } from "./ConfirmDialog";
import { useState, useEffect } from "react";

export default function Ressource() {
  const { data } = useLoaderData();
  const { type, subtype } = useParams();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [rows, setRows] = useState(data);

  const navigate = useNavigate();
  if (!tablesConfig[subtype]) {
    throw new Error(`Table configuration for ${subtype} not found`);
  }
  const globalActions = tablesConfig[subtype].actions?.filter(({ isGlobal }) => isGlobal);
  const inLineActions = tablesConfig[subtype].actions?.filter(({ isGlobal }) => !isGlobal);
  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <>
  
      <div className="flex">
        <main className="flex flex-1 flex-row gap-4 p-4 lg:gap-6 lg:p-6">
          <div className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-lg capitalize font-semibold md:text-2xl">
                {tablesConfig[subtype].title || tablesConfig[subtype].name}
              </h1>
               { globalActions?.map(({ name, label, redirect, handler }) => (
                  <>
                    {redirect ? (
                      <Link to={`/${type}/${subtype}/${name}`}>
                        <Button className="capitalize">{label}</Button>
                      </Link>
                    ) : (
                      <Button className="capitalize" onClick={handler}>
                        {" "}
                        {label}
                      </Button>
                    )}
                  </>
                ))} 
            </div>
            <ConfirmDialog
              open={showConfirmDialog}
              confirmMessage={
                tablesConfig[subtype].actions.find(
                  (a) => a.name === currentAction
                )?.confirmMessage
              }
              onCancel={() => setShowConfirmDialog(false)}
              onConfirm={async () => {
                const clickedAction = tablesConfig[subtype].actions.find(
                  (a) => a.name === currentAction
                );
                if (!clickedAction) {
                  throw new Error(
                    `Action ${currentAction} not configured properly`
                  );
                }
                if (clickedAction.handler) {
                  await clickedAction.handler(currentId);
                  if (clickedAction.updateData)
                    setRows(clickedAction.updateData(rows, { _id: currentId }));
                } else {
                  throw new Error(
                    `Action ${currentAction} not configured properly`
                  );
                }

                setShowConfirmDialog(false);
              }}
            />
            <DataTable
              idKey={tablesConfig[subtype].idKey}
              fields={tablesConfig[subtype].columns}
              data={rows}
              actions={inLineActions}
              onAction={(action, id) => {
                const clickedAction = inLineActions.find(
                  (a) => a.name === action
                );
                console.log(clickedAction);
                if (!clickedAction.confirm) {
                  if (clickedAction.redirect) {
                    navigate(`/${type}/${subtype}/${action}/${id}`);
                  } else {
                    throw new Error(`Action ${action} not configured properly`);
                  }
                } else {
                  console.log("confirm");
                  setShowConfirmDialog(true);
                  setCurrentId(id);
                  setCurrentAction(action);
                }
              }}
            />
          </div>
        </main>
      </div>
    </>
  );
}
