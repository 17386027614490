const reducer =  (state, action) => {
  const { type, payload } = action;
  switch (type) {
  
    case "update_data":
      return {
        ...state,
      };
    case "set_loading":
        return {
            ...state,
            loading: payload,
        };
    case "api_error":
        return {
            ...state,
            error: payload,
        };
    case "clear_error":
        return {
            ...state,
            error: null,
        };
    default:
      return state;
  }
};

export default reducer;