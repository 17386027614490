const globalConfig = {
    brand : "Topcare dashboard",
    productAttributes : [
        'size',
        'weight',
        'material',
        'height',
        'width',
    ],
    roles: [
        {name : 'content-manager', label : 'Content Manager', loginRedirect : '/content-manager'},
        {name : 'products-manager', label : 'Products Manager', loginRedirect : '/content-manager/products'},
        {name : 'inquiries-manager', label : 'Inquiries Manager', loginRedirect : '/inquiries'},
        {name : 'super-admin', label : 'Super Admin', loginRedirect : '/content-manager', notListed : true},
    ],
}
export default globalConfig;

