import React from "react";
import { Input } from "../../ui/input";
import Field from "./Field";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import { Button } from "../../ui/button";
import { ChevronDown } from "lucide-react";

export default function MultiLanguageField({
  languages,
  onChange,
  value,
  type = "text",
  ...rest
}) {
  const handleInputChange = (e) => {
    onChange(e, true, rest.name);
  };
  const [currentLanguage, setCurrentLanguage] = React.useState(languages[0]);
  return (
    <div className="">
      <div className="flex flex-col gap-1">
        <div className=" w-full flex justify-between mb-2">
        <label className="font-bold">{rest.label} :</label>
        <DropdownMenu className='w-full'>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              {currentLanguage.label }<ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {languages.map((language) => {
                return (
                  <DropdownMenuItem

                  onClick={() => setCurrentLanguage(language)}
                    key={language.name}
                    className="capitalize cursor-pointer"
                  >
                    {language.label}
                  </DropdownMenuItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
        </div>
        <div className="w-full">
          
            <div  className="flex flex-col gap-1">
              <Field
                id={currentLanguage.name}
                {...rest}
                name={currentLanguage.name + "_" + rest.name}
                lang={currentLanguage.name}
                onChange={handleInputChange}
                value={( value && value[currentLanguage.name]) || ''}
                type={type}
                hideLabel={true}
              />
            </div>
          
        </div>
      </div>
    </div>
  );
}
