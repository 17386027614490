import axios from "axios";
//const baseUrl = "http://localhost:3033/api";

//const host = 'localhost';

const baseUrl = "http://34.125.73.116:3033/api";

const host = '34.125.73.116';

const formatFilter = (filter) => filter.map((queryParam) => {
  const field = Object.keys(queryParam)[0];
  const value = Object.values(queryParam)[0];
  return `${field}=${value}`;
}).join('&');

const uploadFile = async (file, fieldName) => {
  if(!file) return Promise.resolve({data: {key: ""}} );
  const formData = new FormData();
  formData.append(fieldName, file);
  const response = await axios.post(`${baseUrl}/upload?fieldName=${fieldName}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

const ressource = (ressourceName) => {
  // check if logged in 
  let headers = {}
  if(localStorage.getItem("access_token")) {
    headers.Authorization = "Bearer " + localStorage.getItem("access_token")
  }
  return { 
    getAll: async (populate) => {
      let populateQuery = populate?`?populate=${populate}`:'';
    const response = await axios.get(`${baseUrl}/${ressourceName}/paginate/${populateQuery}`,{headers});
    return response.data;
  },
  getPage: async (page,populate,search,searchFields) => {
    let populateQuery = populate?`?populate=${populate}`:'';
    const response = await axios.get(`${baseUrl}/${ressourceName}/paginate/${populateQuery}&page=${page}${search?`&search=${search}`:''}${searchFields?`&searchFields=${searchFields.join(";")}`:''}`,{headers});
    return response.data;
  },
  getRefs: async (filter,labelField = "") => {
    const query = filter ? formatFilter([filter]) : '';
    const response = await axios.get(`${baseUrl}/${ressourceName}/refs${labelField ? `/${labelField}/` : '/'}?${query}`,{headers});
    return response.data;
  },
  search: async ({filter, populate,customEndpoint}) => {
    try {
      console.log(filter);
      const query = filter ? formatFilter(filter) : '';
      let populateQuery = populate?`&populate=${populate}`:'';
      const searchEndpoint = (customEndpoint || customEndpoint === '' )?customEndpoint:"/search";
      const response = await axios.get(`${baseUrl}/${ressourceName}${searchEndpoint}?${query}${populateQuery}`,{headers});
      return response.data;  
    } catch (error) {
      if(error.response.status === 401) 
        {
          throw new Error(`auth_required`);
        }
        if(error.response.status === 403) 
          {
            throw new Error(`access_denied`);
          }
  
      return []
    }
    
  },
  getById: async(id,populate) => {
    let populateQuery = populate?`?populate=${populate}`:'';
    console.log(id);
    console.log(populateQuery);
    const response = await axios.get(`${baseUrl}/${ressourceName}/${id}/${populateQuery}`,{headers});
    return response.data;
  },
  create : async (data,customEndpoint="")=> {
    const response = await axios.post(`${baseUrl}/${ressourceName}${customEndpoint}`,
      data
    ,{headers});
    return response.data;
  },
  edit: async (data,id,customEndpoint="") => {
    const response = await axios.put(`${baseUrl}/${ressourceName}/${id}${customEndpoint}`,
    data
  ,{headers});
  return response.data;
  },
  remove: async (id) => {
    await axios.delete(`${baseUrl}/${ressourceName}/${id}`,{headers});
  }


};

};
const getDashboardData = async () => {
  const response = await axios.get(`${baseUrl}/dashboard`);
  return response.data;
};
const getThemeColor = async () => {
  const response = await axios.get(`${baseUrl}/color`);
  return response.data.name;
};
const setThemeColor = async (color) => {
  const response = await axios.put(`${baseUrl}/color`,{name:color});
  return response.data.name;
};

const login = async (user) => {
  try {
    const response = await fetch(`${baseUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    const json = await response.json();
    console.log(json);
    return json;
  } catch (error) {
    console.error(error);
  }
};
const refreshToken = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/auth/refresh-tokens`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: localStorage.getItem("refresh_token"),
      }),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    return false;
  }
};
export { ressource, uploadFile, getDashboardData, host, baseUrl, getThemeColor, setThemeColor, login, refreshToken};
